<template>
  <main class="person-page page">
    <BreadcrumbsComponent :title="currentTitle" :links="links" />
    <div class="container-padding">
      <div class="person-page__inner">
        <PersonInfoComponent :person="person" />
        <PersonsComponent :persons="persons" title="Смотрите также" />
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import PersonsComponent from "../components/PersonsComponent.vue";
import PersonInfoComponent from "../components/PersonInfoComponent.vue";
import PERSONS_OPEN_PAGE from "gql/pages/PersonsOpenPage.graphql";

export default {
  name: "PersonPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: PERSONS_OPEN_PAGE,
        variables: {
          id: parseInt(route.params.id),
        },
      })
      .then(({ data }) => {
        store.dispatch("persons/save", data);
      })
      .catch(() => {});
  },
  computed: {
    person() {
      return this.$route.query.minister
        ? this.$store.state.persons.minister
        : this.$store.state.persons.workers_item;
    },
    persons() {
      return this.$store.state.persons.workers_paginate?.data;
    },
    currentTitle() {
      return (
        this.$store.state.persons.workers_item.surname +
        " " +
        this.$store.state.persons.workers_item.name +
        " " +
        this.$store.state.persons.workers_item.patronymic
      );
    },
  },
  data() {
    return {
      links: [
        {
          title: "Правительство",
          to: { name: "persons" },
        },
        // {
        //   title: "Состав",
        //   to: { name: "persons" },
        // },
      ],
      // person: {
      //   src: "/static/images/temp/person-main.png",
      //   title: "Абдулмуслимов Абдулмуслим Мухудинович",
      //   post: "Председатель Правительства РД",
      //   start_date: "с 22 февраля 2022 года",
      //   phone: "+7 (872) 896-89-89",
      //   email: "mail@mail.ru",
      //   socials: [],
      // },
      // persons: [
      //   {
      //     src: "/static/images/temp/person-1.png",
      //     title: "Алиев Руслан Алиевич",
      //     post: "Первый заместитель Председателя Правительства Республики Дагестан",
      //   },
      //   {
      //     src: "/static/images/temp/person-2.png",
      //     title: "Мажонц Манвел Людвигович",
      //     post: "Первый заместитель Председателя Правительства Республики Дагестан",
      //   },
      // ],
    };
  },
  components: {
    PersonInfoComponent,
    PersonsComponent,
    BreadcrumbsComponent,
  },
};
</script>

<style lang="stylus">
.person-page {

  &__inner {
    display flex
    flex-direction column
    gap 60px
  }

  & .persons-component__title {
    font-size 2.125rem
    font-weight 900
    line-height 48px
    color var(--main)
    font-family var(--font-second)
    text-transform uppercase
  }
}
</style>
