<template>
  <div class="person-info">
    <div class="person-info__image">
      <div class="ratio-container">
        <ImgComponent :img="person.img" />
      </div>
    </div>
    <div class="person-info__content">
      <div class="person-info__text">
        <p v-if="person['post']">
          Должность:
          <span>{{ person["post"].title }}</span>
        </p>
        <p v-if="person.in_post_since">
          В должности:
          <span>{{ person.in_post_since }}</span>
        </p>
      </div>
      <div v-if="person.phone || person.email" class="person-info__text">
        <p v-if="person.phone">
          Телефон:
          <span>{{ person.phone }}</span>
        </p>
        <p v-if="person.email">
          Email:
          <span>{{ person.email }}</span>
        </p>
      </div>
      <div class="person-info__socials" v-if="person.socials && person.socials.length">
        <a :href="social.link" target="_blank" v-for="(social, i) in person.socials" :key="i">
          <IconComponent category="default" :name="social.type.code | socialIconName" />
        </a>
      </div>
      <div class="person-info__article">
        <p>Биография</p>
        <EditorJSComponent
          v-if="$options.filters.editor_has_text(person.description)"
          :text="person.description"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "PersonInfoComponent",
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    IconComponent,
    EditorJSComponent,
    ImgComponent,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.person-info {
  display grid
  grid-template-columns 440fr 920fr
  gap 40px

  &__image {
    ratio(440, 440, contain)
  }

  &__content {
    display flex
    flex-direction column
    gap 30px
  }

  &__text {
    display flex
    flex-direction column
    gap 15px
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px
    z-index 1

    a {
      background var(--white)
      border 1px solid var(--gray-dark)
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      .icon {
        max-width 20x
        max-height: 20px

        svg path {
          fill var(--dark)
        }
      }
    }
  }

  &__article {
    display flex
    flex-direction column
    gap 20px
  }

}
</style>
